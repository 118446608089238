import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/home/index"

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function (location) {
	return originalPush.call(this, location).catch((err) => { })
}

//import "vant/lib/index.css"
Vue.use(VueRouter)

const routes = [
	// {
	// 	path: "/",
	// 	redirect: "",
	// },
	{
		path: "",
		name: "Home",
		component: Home,
		meta: {
			isShow: true,
		}
	},

]

const router = new VueRouter({
	mode: "hash",
	base: process.env.BASE_URL,
	routes,
})

router.beforeEach((to, from, next) => {
	// if (to.path == "/cart") {
	// 	let token = localStorage.getItem("token")
	// 	if (token) {
	// 		next()
	// 	} else {
	// 		next("/user")
	// 		Vue.prototype.$toast("请先登陆")
	// 		// setTimeout(() => {
	// 		// 	next("/user")
	// 		// }, 500)
	// 	}
	// }
	next()
})
export default router
