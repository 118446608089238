<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import "@/vantui";
export default {};
</script>
<style lang="less" scoped>
body,
html {
  height: 100%;
}
html {
  font-size: 100px !important;
}
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

/*nav a.router-link-exact-active {
  color: #42b983;
}*/
</style>
