
<template>
  <div class="box">
    <div
      class="icon"
      @dblclick="viewBtn"
      ref="clickMe"
    >
      <!-- <van-icon name="chat-o" /> -->
    </div>
    <div class="title">
      <span>{{ title }}</span>
    </div>
    <div class="iframe">
      <iframe
        :src="link"
        class="iframe_box"
      ></iframe>
    </div>
    <div class="block">
      <van-pagination
        v-model="currentPage"
        :items-per-page="1"
        :page-count="list.length"
        @change="change"
      >
        <template #prev-text>
          <van-icon name="arrow-left" />
        </template>
        <template #next-text>
          <van-icon name="arrow" />
        </template>
        <template #page="{ text }">{{ text }}</template></van-pagination>
    </div>
    <audio
      muted=“muted”
      ref="audio"
      preload="auto"
      loop
      id="audio"
      src="../../assets/群星 - 广陵散 (古琴).mp3"
    ></audio>
  </div>
</template>
<script>
import screenfull from "screenfull";
export default {
  data() {
    return {
      list: [
        {
          title: "黑白陶瓷猫_白",
          link: "https://www.realibox.com/sl?p=UuO3Lb",
        },
        {
          title: "黑白陶瓷猫_黑",
          link: "https://www.realibox.com/sl?p=4uNw0d",
        },
        {
          title: "福字框",
          link: "https://www.realibox.com/sl?p=ZIIBzc",
        },
        {
          title: "国潮保温杯",
          link: "https://www.realibox.com/sl?p=Xazft",
        },
        {
          title: "黑白猫柚花茶_白",
          link: "https://www.realibox.com/sl?p=6miHse",
        },
        {
          title: "黑白猫柚花茶_黑",
          link: "https://www.realibox.com/sl?p=aKSTKc",
        },
        {
          title: "戒懒系列笔记袋_红",
          link: "https://www.realibox.com/sl?p=Vv04b",
        },
        {
          title: "戒懒系列笔记袋_黄",
          link: "https://www.realibox.com/sl?p=LveZ4c",
        },
        {
          title: "戒懒系列笔记袋_蓝",
          link: "https://www.realibox.com/sl?p=IZhsS",
        },
        {
          title: "戒懒系列口袋笔记本",
          link: "https://www.realibox.com/sl?p=7Me2Pb",
        },
        {
          title: "戒懒系列签字笔",
          link: "https://www.realibox.com/sl?p=6HxZz",
        },
        {
          title: "戒懒系列特种纸笔记本_橙",
          link: "https://www.realibox.com/sl?p=VSS18d",
        },
        {
          title: "戒懒系列特种纸笔记本_蓝",
          link: "https://www.realibox.com/sl?p=GWNIue",
        },
        {
          title: "黑白猫套装杯_白",
          link: "https://www.realibox.com/sl?p=4RgNtc",
        },
        {
          title: "黑白猫套装杯_黑",
          link: "https://www.realibox.com/sl?p=3qZc6e",
        },
        {
          title: "黑白猫加热杯_白",
          link: "https://www.realibox.com/sl?p=ZxjzAd",
        },
        {
          title: "黑白猫加热杯_黑",
          link: "https://www.realibox.com/sl?p=0dmlee",
        },
        {
          title: "小平印记-纪念徽章",
          link: "https://www.realibox.com/sl?p=ZZ35Ad",
        },
        {
          title: "戒懒系列书签",
          link: "https://www.realibox.com/sl?p=SMiJCe",
        },
      ],
      currentPage: 1,
      link: "https://www.realibox.com/sl?p=UuO3Lb",
      title: "黑白陶瓷猫_白",
      isOff: true,
      isScreenfull: false,
    };
  },
  mounted() {
    this.$refs.clickMe.click();
    console.log("1111");
    // 自动播放音乐效果，解决微信自动播放问题
    this.audioAutoPlay();
    let oAudio = document.querySelector("#audio");
    oAudio.onended = function () {
      //播放完毕，重新循环播放
      oAudio.load();
      oAudio.play();
    };
    this.$nextTick(() => {
      this.$refs.audio.play();
    });
  },

  // watch: {
  //   list: {
  //     handler() {
  //       this.$nextTick(() => {
  //         var mySwiper = new Swiper(".swiper-container", {
  //           loop: true,
  //           // 如果需要分页器
  //           pagination: {
  //             el: ".swiper-pagination",
  //             clickable: true,
  //           },

  //           // 如果需要前进后退按钮
  //           navigation: {
  //             nextEl: ".swiper-button-next",
  //             prevEl: ".swiper-button-prev",
  //           },
  //         });
  //         console.log(mySwiper);
  //       });
  //     },
  //   },
  // },
  methods: {
    change(data) {
      console.log(data);
      this.title = this.list[data - 1].title;
      this.link = this.list[data - 1].link;
    },
    pervBtn() {
      console.log("11111");
    },
    audioAutoPlay() {
      let audio = document.getElementById("audio");
      this.isOff = false;

      audio.play();
      document.removeEventListener("touchstart", this.audioAutoPlay);
    },
    viewBtn() {
      if (screenfull.isFullscreen) {
        screenfull.exit();
      } else {
        screenfull.request();
      }
      console.log("222222");
    },
  },
};
</script>
<style lang="less" scoped>
.box {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.iframe_box {
  width: 100%;
  height: 100%;
}
.iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.left_box {
  position: absolute;
  top: 50%;
  left: 0;
  border: 1px solid black;
  border-radius: 100%;
  display: flex;
  opacity: 0.5;
  justify-content: center;
  align-items: center;
  margin: -0.5rem 0 0 0.5rem;
  z-index: 100;
  > i {
    font-size: 50px;
  }
}
.right_box {
  position: absolute;
  top: 50%;
  right: 0;
  opacity: 0.5;
  border: 1px solid black;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -0.5rem 0.5rem 0 0;
  z-index: 1;
  > i {
    font-size: 50px;
  }
}
.block {
  position: absolute;
  bottom: 20vh;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10;
}
.right_box:visited {
  border-color: rgba(28, 157, 255, 1);
  color: rgba(28, 157, 255, 1);
}
.title {
  position: absolute;
  top: 10vh;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10;
  width: 100%;
  text-align: center;
  > span {
    font-size: 32px;
    font-weight: 500;
  }
}
.icon {
  position: absolute;
  right: 0;
  top: 0;
  width: 15vw;
  height: 10vh;
  z-index: 10;
}
</style>