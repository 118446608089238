import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		name:'',
	},
	getters: {
		name:(state)=>state.name,

	},
	mutations: {
		setName(state, str){
			state.name = str;
		  },

	},
	actions: {
		setName(context, payload) {
			context.commit("setName", payload);
		  },

	},
	modules: {
		setName(state, str){
			state.name = str;
		  },
	},
	plugins: [createPersistedState({
		　　storage:window.sessionStorage,
	// 	　　reducer(val)  {
	// 			// 此时，当count发生改变的时候，就会调用此函数，并且val的值为当前state对象，return的值为当前本地存储的value值（本地存储的key值为vuex）
	// 			return {
	// 				count: val.count,
	//    　　　　  　　 changeCount: 'a'
	// 			}
	// 		}
		})]
})
